import { DEFAULT_RULESET_TYPE } from 'interfaces/enums';
import { getFileName, getServerUrl, toFormattedDateString } from 'util/Util';

import {
  toDunningSelectionConfigurationDto,
  toExecutionFilterDto,
  toExecutionFilterQuery,
  toOrderRulesRequest,
  toRuleCreateDto,
  toRuleCreateDtoFromRuleForReact,
  toRuleDto,
  toRuleDtoFromRuleForeReact,
  toRuleUpdateDtofromRule,
  toSystemPropertyDto,
} from './ApiServiceMappers';
import {
  ArchivedRuleSetDtoToJSON,
  BasicRuleSetDtoToJSON,
  BulkExecutionStatsDto,
  Configuration,
  ContractRulesetCustomizationApi,
  type ContractRuleSetCustomizationResponseDto,
  ContractStateApi,
  ContractStateFilter,
  ContractStateOverview,
  DefaultRulesetApi,
  DefaultRuleSetDtoToJSON,
  DownloadContractStatesRequest,
  DunningLevelApi,
  DunningLevelDtoToJSON,
  DunningPageRequestFromJSON,
  DunningSelectionApi,
  DunningSelectionConfigurationDtoToJSON,
  ExecutionFilterDtoFromJSON,
  ExecutionsApi,
  ForecastApi,
  ForecastDtoToJSON,
  GetContractStatesByFilterRequest,
  GetExecutionsRequest,
  GetRuleSetsRequest,
  HTTPQuery,
  LicenseApi,
  LicenseDtoToJSON,
  PaginatedContractStateOverviewToJSON,
  PaginatedExecutionOverviewDtoToJSON,
  RuleAdviceDtoToJSON,
  RuleDtoToJSON,
  RuleParameterDtoFromJSON,
  RulesetApi,
  RulesetAssignmentCriteriaApi,
  RuleSetAssignmentCriteriaDtoFromJSON,
  RuleSetAssignmentCriteriaDtoToJSON,
  RuleSetAssignmentCriteriaUpdateDtoFromJSON,
  RuleSetDto,
  RuleSetDtoToJSON,
  RuleSetDunningLevelDtoFromJSON,
  RuleSetExecutionsSummaryDtoToJSON,
  RuleSetFilterDto,
  RuleSetInfoDtoFromJSON,
  RuleSetInfoDtoToJSON,
  RuleSetReplacementDtoToJSON,
  RuleSetReplacementLogDtoToJSON,
  SearchContractRuleSetCustomizationsRequest,
  SystemPropertiesApi,
  SystemPropertyDtoToJSON,
} from '../generated-sources/openapi';
import { RuleSetAssignmentCriteriaForReact } from '../interfaces/DynamicCriteriaInterfaces';
import {
  type AbstractRuleSet,
  type BasicRuleSet,
  type BasicRuleSetInfo,
  CommunicationProfile,
  type DefaultRuleSet,
  DunningLevel,
  type DunningLevelForReact,
  type DunningSelectionConfiguration,
  type DunningSelectionForecast,
  type Execution,
  type ExecutionFilter,
  type Page,
  Param,
  type ParamForReact,
  Rule,
  type RuleAdvice,
  RuleForReact,
  type RuleSet,
  RuleSetExecutionsSummary,
  RuleSetForReact,
  type RuleSetReplacementLog,
  type RuleTemplate,
  type SystemProperty,
} from '../interfaces/Interfaces';
import { AppLicense } from '../license/AppLicense';

const DUNNING_URL: string = getServerUrl();

const jwtCRMIToken: any = (window as any).JwtForDunning;

function getAuthHeader() {
  const isCRMITokenDefined = jwtCRMIToken !== 'undefined';
  if (!isCRMITokenDefined) {
    throw new Error('No JWT is provided!');
  }
  const sessionToken = sessionStorage.getItem('jwt');
  if (sessionToken) {
    return `Bearer ${sessionToken}`;
  }
  return `Bearer ${jwtCRMIToken}`;
}

export function getHeaderConfig() {
  return {
    basePath: DUNNING_URL.endsWith('/') ? DUNNING_URL.slice(0, -1) : DUNNING_URL,
    headers: { Authorization: getAuthHeader() },
  };
}

export function getLicenseModel(): Promise<AppLicense> {
  return new LicenseApi(new Configuration(getHeaderConfig())).getLicense().then(license => LicenseDtoToJSON(license));
}

export async function getBasicRuleSets(ruleSetFilterDto?: RuleSetFilterDto): Promise<AbstractRuleSet[]> {
  const request = {} as GetRuleSetsRequest;
  request.ruleSetFilterDto = { states: ruleSetFilterDto?.states, excludeDefault: ruleSetFilterDto?.excludeDefault };
  const headerConfig = getHeaderConfig();
  (headerConfig as any).queryParamsStringify = (params: HTTPQuery) => {
    let result = '';
    if (params?.ruleSetFilterDto) {
      const filterDto: RuleSetFilterDto = params.ruleSetFilterDto as RuleSetFilterDto;
      if (filterDto.states) {
        result = filterDto.states.map(k => `states=${k.toString()}`).join('&');
      }
      if (filterDto?.excludeDefault === true || filterDto?.excludeDefault === false) {
        const excludeDefault = `excludeDefault=${filterDto.excludeDefault.toString()}`;
        result = result.length > 0 ? `${result}&${excludeDefault}` : excludeDefault;
      }
    }

    return result;
  };

  return new RulesetApi(new Configuration(headerConfig))
    .getRuleSets(request)
    .then(result => result.ruleSets.map(k => BasicRuleSetDtoToJSON(k)));
}

export async function getSubsequentRuleSets(): Promise<BasicRuleSet[]> {
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .getAllSubsequent()
    .then(result => result.ruleSets.map(k => BasicRuleSetDtoToJSON(k)));
}

export async function getRuleSet(ruleSetId: string): Promise<RuleSetForReact> {
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .getByRuleSetId({ rulesetId: ruleSetId })
    .then(response => new RuleSetForReact(RuleSetDtoToJSON(response)));
}

export async function getAllDunningLevels(): Promise<DunningLevel[]> {
  return new DunningLevelApi(new Configuration(getHeaderConfig()))
    .getDunningLevels()
    .then(dunningLevel => dunningLevel.map(k => DunningLevelDtoToJSON(k)));
}

export async function getRulesetDynamicCriteria(ruleSetId: string): Promise<RuleSetAssignmentCriteriaForReact> {
  return new RulesetAssignmentCriteriaApi(new Configuration(getHeaderConfig()))
    .getByRulesetId({ rulesetId: ruleSetId })
    .then(response => new RuleSetAssignmentCriteriaForReact(RuleSetAssignmentCriteriaDtoToJSON(response)));
}

export async function createRulesetDynamicCriteria(
  criteria: RuleSetAssignmentCriteriaForReact,
): Promise<RuleSetAssignmentCriteriaForReact> {
  return new RulesetAssignmentCriteriaApi(new Configuration(getHeaderConfig()))
    .create1({
      rulesetId: criteria.ruleSetId,
      ruleSetAssignmentCriteriaCreateDto: RuleSetAssignmentCriteriaDtoFromJSON(criteria),
    })
    .then(result => new RuleSetAssignmentCriteriaForReact(RuleSetAssignmentCriteriaDtoToJSON(result)));
}

export async function updateRulesetDynamicCriteria(
  criteria: RuleSetAssignmentCriteriaForReact,
): Promise<RuleSetAssignmentCriteriaForReact> {
  return new RulesetAssignmentCriteriaApi(new Configuration(getHeaderConfig()))
    .update({
      rulesetId: criteria.ruleSetId,
      id: criteria.id,
      ruleSetAssignmentCriteriaUpdateDto: RuleSetAssignmentCriteriaUpdateDtoFromJSON(criteria),
    })
    .then(result => new RuleSetAssignmentCriteriaForReact(RuleSetAssignmentCriteriaDtoToJSON(result)));
}

export async function createRule(ruleSetId: string, orderValue: number, rule: RuleTemplate): Promise<RuleForReact> {
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .addRule({ rulesetId: ruleSetId, ruleCreateDto: toRuleCreateDto(orderValue, rule) })
    .then(result => new RuleForReact(RuleDtoToJSON(result)));
}

export async function checkRuleForAdvices(
  ruleSetId: string,
  orderValue: number,
  rule: RuleTemplate,
): Promise<RuleAdvice[]> {
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .checkForAdvices({ ruleDto: toRuleDto(ruleSetId, orderValue, rule) })
    .then(result => result.map(k => RuleAdviceDtoToJSON(k)));
}

export async function copyRule(ruleForReact: RuleForReact, ruleSetId: string): Promise<RuleForReact> {
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .addRule({
      rulesetId: ruleSetId,
      ruleCreateDto: toRuleCreateDtoFromRuleForReact(ruleForReact),
    })
    .then(result => new RuleForReact(RuleDtoToJSON(result)));
}

export async function updateRule(rule: RuleForReact) {
  const content = new Rule(rule);
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .updateRule({ ruleId: rule.id, rulesetId: rule.rulesetId, ruleUpdateDto: toRuleUpdateDtofromRule(content) })
    .then(result => new RuleForReact(RuleDtoToJSON(result)));
}

export async function updateRuleNameAndDescription(
  rulesetId: string,
  ruleId: string,
  name: string,
  description: string,
) {
  await new RulesetApi(new Configuration(getHeaderConfig())).updateRuleName({
    ruleId,
    rulesetId,
    ruleNameUpdateDto: { name },
  });

  return new RulesetApi(new Configuration(getHeaderConfig()))
    .updateRuleDescription({
      ruleId,
      rulesetId,
      ruleDescriptionUpdateDto: { description },
    })
    .then(result => RuleDtoToJSON(result));
}

export async function deleteRule(ruleSetId: string, ruleId: string) {
  return new RulesetApi(new Configuration(getHeaderConfig())).deleteRule({ ruleId, rulesetId: ruleSetId });
}

export async function createRuleSet(
  ruleSetName: string,
  ruleSetDescription: string,
  params: Param[],
  dunningLevels: DunningLevelForReact[],
  rules?: RuleForReact[],
  subsequentRuleset?: BasicRuleSet,
): Promise<RuleSetForReact> {
  const result = {} as RuleSetDto;
  result.name = ruleSetName;
  result.description = ruleSetDescription;
  result.dunningLevels = dunningLevels
    ?.map(k => JSON.stringify(k))
    .map(k => JSON.parse(k))
    .map(k => RuleSetDunningLevelDtoFromJSON(k));
  result.parameters = params
    ?.map(k => JSON.stringify(k))
    .map(k => JSON.parse(k))
    .map(k => RuleParameterDtoFromJSON(k));
  result.rules = rules
    ?.map(k => JSON.stringify(k))
    .map(k => JSON.parse(k))
    .map(k => toRuleDtoFromRuleForeReact(k));
  if (subsequentRuleset !== undefined) {
    const subSequentAsString = JSON.stringify(subsequentRuleset);
    result.subsequentRuleset = RuleSetInfoDtoFromJSON(JSON.parse(subSequentAsString));
  }
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .create({ ruleSetDto: result })
    .then(result => new RuleSetForReact(RuleSetDtoToJSON(result)));
}

export async function updateRuleSet(
  ruleSetId: string,
  ruleSetName: string,
  ruleSetDescription: string,
  newParams: ParamForReact[],
  newDunningLevels: DunningLevelForReact[],
  subsequentRuleset?: BasicRuleSet,
) {
  const content = {
    rulesetId: ruleSetId,
    name: ruleSetName,
    description: ruleSetDescription,
    parameters: newParams.map(param => {
      return new Param(param);
    }),
    dunningLevels: newDunningLevels.map(level => {
      return new DunningLevel(level);
    }),
    subsequentRuleset,
  };

  return new RulesetApi(new Configuration(getHeaderConfig()))
    .updateRuleSet({
      rulesetId: ruleSetId,
      ruleSetUpdateDto: content,
    })
    .then(result => RuleSetDtoToJSON(result));
}

export async function updateRuleSetNameAndDescription(
  ruleSetId: string,
  ruleSetName: string,
  ruleSetDescription: string,
): Promise<BasicRuleSetInfo> {
  const content = {
    name: ruleSetName,
    description: ruleSetDescription,
  };
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .updateInfo({
      rulesetId: ruleSetId,
      ruleSetUpdateDto: content,
    })
    .then(result => RuleSetInfoDtoToJSON(result));
}

export function deleteRuleSet(rulesetId: string) {
  return new RulesetApi(new Configuration(getHeaderConfig()))._delete({ rulesetId });
}

export async function findRuleSetCustomizations(
  searchDto: SearchContractRuleSetCustomizationsRequest,
): Promise<ContractRuleSetCustomizationResponseDto[]> {
  const configuration = new Configuration(getHeaderConfig());
  return new ContractRulesetCustomizationApi(configuration).searchContractRuleSetCustomizations(searchDto);
}

export async function migrateRuleSet(oldRulesetId: string, newRulesetId: string) {
  return new ContractRulesetCustomizationApi(new Configuration(getHeaderConfig())).migrate({
    ruleSetMigrationDto: { oldRulesetId, newRulesetId },
  });
}

export async function getRegularDefaultRuleSet(): Promise<DefaultRuleSet> {
  return new DefaultRulesetApi(new Configuration(getHeaderConfig()))
    .getDefaultRuleSetByTarget({ contractsTarget: 'REGULAR' })
    .then(ruleSet => DefaultRuleSetDtoToJSON(ruleSet));
}

export async function getDefaultRuleSets(): Promise<DefaultRuleSet[]> {
  return new DefaultRulesetApi(new Configuration(getHeaderConfig()))
    .getDefaultRuleSets()
    .then(ruleSetArray => ruleSetArray.map(k => DefaultRuleSetDtoToJSON(k)));
}

export async function getFinallyInvoicedDefaultRuleSet(): Promise<DefaultRuleSet> {
  return new DefaultRulesetApi(new Configuration(getHeaderConfig()))
    .getDefaultRuleSetByTarget({ contractsTarget: 'FINALLY_INVOICED' })
    .then(ruleSet => DefaultRuleSetDtoToJSON(ruleSet));
}

export async function setDefaultRuleSet(
  ruleSetForReact: RuleSetForReact,
  type: DEFAULT_RULESET_TYPE,
): Promise<RuleSetReplacementLog> {
  const content = {
    id: ruleSetForReact.rulesetId,
    contractTargetType: type,
  };
  return new DefaultRulesetApi(new Configuration(getHeaderConfig()))
    .set({ defaultRuleSetDto: content })
    .then(result => RuleSetReplacementDtoToJSON(result));
}

export async function simulateReplaceRuleset(
  rulesetId: string,
  type: DEFAULT_RULESET_TYPE,
): Promise<RuleSetReplacementLog> {
  const content = {
    id: rulesetId,
    contractTargetType: type,
  };
  return new DefaultRulesetApi(new Configuration(getHeaderConfig()))
    .checkAssignment({ defaultRuleSetDto: content })
    .then(result => RuleSetReplacementLogDtoToJSON(result));
}

function convertToQueryParameter(
  filterDto:
    | string
    | number
    | Array<string | number | boolean | null>
    | Set<string | number | boolean | null>
    | HTTPQuery
    | boolean,
) {
  return Object.entries(filterDto)
    .filter(key => key[1] !== undefined)
    .map(e => {
      if (e[1] instanceof Date) {
        return `${e[0]}=${toFormattedDateString(e[1])}`;
      }
      return e.join('=');
    })
    .join('&');
}

function getQueryParamsStringify() {
  return (params: HTTPQuery) => {
    let result = '';
    const { filterDto } = params;
    if (filterDto !== null) {
      const queryParams = convertToQueryParameter(filterDto);
      result = queryParams.length > 0 ? queryParams : '';
    }
    const pagingRequest = params.dunningPageRequest;
    if (pagingRequest !== undefined && pagingRequest !== null) {
      const pagingParams = convertToQueryParameter(pagingRequest);
      result = pagingParams.length > 0 && result.length > 0 ? `${result}&${pagingParams}` : pagingParams;
    }
    return result;
  };
}

export function getExecutions(
  options: {
    page?: number;
    pageSize?: number;
  } & ExecutionFilter,
  abortSignal?: AbortSignal,
): Promise<Page<Execution>> {
  const request = {} as GetExecutionsRequest;
  const optionsAsString = JSON.stringify(options);
  request.filterDto = ExecutionFilterDtoFromJSON(JSON.parse(optionsAsString));
  request.dunningPageRequest = DunningPageRequestFromJSON(JSON.parse(optionsAsString));
  const headerConfig = getHeaderConfig();
  (headerConfig as any).queryParamsStringify = getQueryParamsStringify();
  return new ExecutionsApi(new Configuration(headerConfig))
    .getExecutions(request, { signal: abortSignal })
    .then(result => PaginatedExecutionOverviewDtoToJSON(result));
}

export async function getContractStates(
  page: number,
  pageSize: number,
  contractStateFilter: ContractStateFilter,
): Promise<Page<ContractStateOverview>> {
  const request = {} as GetContractStatesByFilterRequest;
  request.filterDto = contractStateFilter;
  request.dunningPageRequest = {
    page,
    pageSize,
  };
  const headerConfig = getHeaderConfig();
  (headerConfig as any).queryParamsStringify = getQueryParamsStringify();
  return new ContractStateApi(new Configuration(headerConfig))
    .getContractStatesByFilter(request)
    .then(result => PaginatedContractStateOverviewToJSON(result));
}

export async function downloadContractStates(filter: ContractStateFilter, abortController?: AbortController) {
  const request = {} as DownloadContractStatesRequest;
  request.filterDto = filter;
  const headerConfig = getHeaderConfig();
  (headerConfig as any).queryParamsStringify = getQueryParamsStringify();

  return new ContractStateApi(new Configuration(headerConfig))
    .downloadContractStatesRaw(request, { signal: abortController?.signal })
    .then(response => response.raw);
}

export async function getSystemProperties(): Promise<SystemProperty[]> {
  return new SystemPropertiesApi(new Configuration(getHeaderConfig()))
    .getProperties()
    .then(propertyArray => propertyArray.map(k => SystemPropertyDtoToJSON(k)));
}

export async function updateSystemProperties(sp: SystemProperty[]): Promise<void> {
  const requests = sp.map(k => toSystemPropertyDto(k));
  await new SystemPropertiesApi(new Configuration(getHeaderConfig())).updateProperties({ systemPropertyDto: requests });
}

export async function activateRuleSet(rulesetId: string) {
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .makeRuleSetActive({ rulesetId })
    .then(ruleset => RuleSetDtoToJSON(ruleset));
}

export async function archiveRuleSet(rulesetId: string) {
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .archive({ rulesetId })
    .then(ruleset => ArchivedRuleSetDtoToJSON(ruleset));
}

export async function getExecutionsFromRuleSet(rulesetId: string): Promise<RuleSetExecutionsSummary> {
  return new ExecutionsApi(new Configuration(getHeaderConfig()))
    .getRuleSetSummary({ ruleSetId: rulesetId })
    .then(summary => RuleSetExecutionsSummaryDtoToJSON(summary));
}

export async function orderRules(rulesetId: string, orderedRuleIds: string[]): Promise<RuleSet> {
  const requestParameter = toOrderRulesRequest(rulesetId, orderedRuleIds);
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .orderRules(requestParameter)
    .then(ruleSet => RuleSetDtoToJSON(ruleSet));
}

export async function getDunningSelection(): Promise<DunningSelectionConfiguration> {
  return new DunningSelectionApi(new Configuration(getHeaderConfig()))
    .getDunningSelection()
    .then(selection => DunningSelectionConfigurationDtoToJSON(selection))
    .then(result => JSON.parse(JSON.stringify(result)));
}

export async function updateDunningSelection(dunningSelection: DunningSelectionConfiguration): Promise<void> {
  const requestParameters = { dunningSelectionConfigurationDto: toDunningSelectionConfigurationDto(dunningSelection) };
  await new DunningSelectionApi(new Configuration(getHeaderConfig())).updateDunningSelection(requestParameters);
}

export async function getDunningSelectionForecast(
  dunningSelection: DunningSelectionConfiguration,
): Promise<DunningSelectionForecast> {
  const requestParameters = { dunningSelectionConfigurationDto: toDunningSelectionConfigurationDto(dunningSelection) };
  return new ForecastApi(new Configuration(getHeaderConfig()))
    .getForecastByConfigurationDto(requestParameters)
    .then(forecast => ForecastDtoToJSON(forecast));
}

export async function bulkRetryExecutionOutcome(filters: ExecutionFilter): Promise<BulkExecutionStatsDto> {
  return new ExecutionsApi(new Configuration(getHeaderConfig())).bulkExecution({
    executionFilterDto: toExecutionFilterDto(filters),
  });
}

export function getCommunicationProfiles(): CommunicationProfile[] {
  let communicationProfileMap: any = (window as any).CommunicationProfiles;
  if (!communicationProfileMap) {
    communicationProfileMap = {
      1: 'Digital First',
      2: 'Analog First',
      3: 'TEST',
      4: 'Com prof test',
      6: 'SMS',
      7: 'Online',
      8: 'Post Kunden',
      9: 'WhatsApp',
    };
  }

  const result: CommunicationProfile[] = [];

  if (communicationProfileMap && typeof communicationProfileMap === 'object') {
    const keys = Object.keys(communicationProfileMap);
    for (const key of keys) {
      result.push(new CommunicationProfile(Number(key), communicationProfileMap[key]));
    }
    return result;
  }

  return [];
}

export async function downloadDunningForecastCSV(dunningSelection: DunningSelectionConfiguration) {
  const requestParameters = { dunningSelectionConfigurationDto: toDunningSelectionConfigurationDto(dunningSelection) };
  return new ForecastApi(new Configuration(getHeaderConfig())).download(requestParameters).then(response => {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = getFileName('dunnable_contracts.csv');

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  });
}

export async function downloadExecutedActions(filter: ExecutionFilter, abortController?: AbortController) {
  const requestParameters = toExecutionFilterQuery(filter);
  return new ExecutionsApi(new Configuration(getHeaderConfig()))
    .downloadExecutionsRaw(requestParameters, { signal: abortController?.signal })
    .then(response => response.raw);
}

export function getBulkRetryStatus() {
  return new ExecutionsApi(new Configuration(getHeaderConfig())).retryStatus();
}

export function getArchivedRuleSets() {
  return new RulesetApi(new Configuration(getHeaderConfig()))
    .getArchivedRuleSets()
    .then(ruleSetArray => ruleSetArray.map(k => ArchivedRuleSetDtoToJSON(k)));
}

export async function deleteExecutions(contractIds?: number[]): Promise<void> {
  const contractIdsSet = contractIds ? new Set(contractIds) : undefined;
  await new ExecutionsApi(new Configuration(getHeaderConfig())).deleteExecutions({ contractIds: contractIdsSet });
}
